<template>
    <div>
        <div v-if="user.user_id" class="is-flex is-justify-content-center">
            <div class="bg-sky-100 py-4 border border-slate-200 px-5 mt-3" style="min-width: 25%;">
                <div class="mb-2">
                    <p class="border-b border-slate-200">
                        Emri: 
                        <span class="is-pulled-right"> {{user.user_name}} </span>
                    </p>
                </div>
                <div class="mb-2">
                    <p class="border-b border-slate-200">
                        Email: <span class="is-pulled-right">{{user.user_email}}</span>
                    </p>
                </div>
                <div class="mb-4">
                    <p class="border-b border-slate-200">
                        Roli: <span class="is-pulled-right">{{user.role_name}}</span>
                    </p>
                </div>
                <div class="is-flex is-justify-content-space-between">
                    <div>Pika shitjesh: </div>
                    <div class="is-flex is-flex-direction-column">
                        <span v-for="(pos, index) in pos_list" :key="index">{{ pos.name }}</span>
                    </div>
                </div>
                <div class="mt-3">
                    <div v-if="!show_chane_password" class="has-text-centered">
                        <a @click.prevent="start_chakne_password" class="button is-small is info" href="">Ndrysho fjalekalim</a>
                    </div>
                    <div v-else class="has-background-white p-3">
                        <p>
                            <button @click.prevent="cancel_change_password()" class="delete is-pulled-right is-small"></button>
                        </p>

                        <!-- OLD Password input-->
                        <div class="field">
                            <label class="label" for="current_password">Fjalekalimi i vjeter</label>
                            <div class="control">
                                <input id="current_password" v-model="current_password"
                                    name="current_password"
                                    type="password"
                                    placeholder="" class="input is-success is-small"
                                    readonly
                                    onfocus="this.removeAttribute('readonly');">
                                <!-- <p class="help">Specify your password</p> -->
                            </div>
                        </div>

                        <!-- Password input-->
                        <div class="field">
                            <label class="label" for="passwordinput-0">Fjalekalimi i ri</label>
                            <div class="control">
                                <input id="passwordinput-0" v-model="new_password"
                                    name="passwordinput-0"
                                    type="password"
                                    placeholder="" class="input is-small"
                                    readonly
                                    onfocus="this.removeAttribute('readonly');">
                                <!-- <p class="help">Specify your password</p> -->
                            </div>
                        </div>

                        <!-- Password input-->
                        <div class="field">
                            <label class="label" for="passwordinput-1">Perserit fjalekalimin e ri</label>
                            <div class="control">
                                <input id="passwordinput-1"
                                    v-model="new_confirm_password" name="passwordinput-1"
                                    type="password" placeholder="" class="input is-small" 
                                    readonly onfocus="this.removeAttribute('readonly');">
                                <p v-if="!can_change_password && (this.new_password != '' && this.new_confirm_password != '')" class="help has-text-danger">Vendos te njejtin fjalekalim ne te 2-ja fushat</p>
                            </div>
                        </div>
                        <div>
                            <a v-if="can_change_password" @click.prevent="change_password()" class="button is-small is-success is-fullwidth" href="">Ndrysho</a>
                            <a @click.prevent="() => {}" v-else disabled=true class="button is-small is-info is-fullwidth">Ndrysho</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../services/Api'
export default {
    data() {
        return {
            user: {},
            show_chane_password: false,
            current_password: '',
            new_password: '',
            new_confirm_password: '',
            pos_list: []

        }
    },
    created(){
        this.get_profile_data()
    },
    methods: {
        get_profile_data(){
            Api(true).post('/profile/get/data')
            .then(r => {
                this.user = r.data.user[0]
                this.pos_list = r.data.pos_list
            })
        },
        start_chakne_password(){
            this.show_chane_password = true
            this.current_password = ''
            this.new_password = '',
            this.new_confirm_password = ''
        },
        cancel_change_password(){
            this.show_chane_password = false
            this.new_password = '',
            this.new_confirm_password = ''
        },
        change_password(){
            Api(true).post('/profile/change/password', {
                current_password: this.current_password,
                new_password: this.new_password, 
                new_confirm_password: this.new_confirm_password
            }).then(res => {
                if(res.data.changed == 1){
                    this.$toast.success('Password-i u ndryshua me sukses.')
                    this.cancel_change_password()
                }
            })
        },
    },
    computed: {
        can_change_password(){
            var a = false
            if(this.new_password == this.new_confirm_password && (this.new_password != '' || this.new_confirm_password != '' )) a = true
            return a
        }
    }

}
</script>

<style>

</style>